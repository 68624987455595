// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap/dist/js/bootstrap")

document.addEventListener("turbolinks:load", function() {
  // Seleciona todos os botões de verificar conexão
  const buttons = document.querySelectorAll(".check-connection-btn");

  // Busca o token CSRF do meta tag
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  buttons.forEach(button => {
    button.addEventListener("click", function(event) {
      const empresaId = this.getAttribute("data-id");

      fetch(`/empresas/${empresaId}/check_connection`, {
        method: "GET",
        headers: {
          'X-CSRF-Token': csrfToken
        }
      })
      .then(response => response.json())
      .then(data => {
        const statusElement = document.getElementById(`status-empresa-${empresaId}`);

        if (data.status === 'ativo') {
          statusElement.innerText = "Conexão Ativa";
          statusElement.classList.add('text-success');
          statusElement.classList.remove('text-danger');
        } else {
          statusElement.innerText = "Conexão Inativa";
          statusElement.classList.add('text-danger');
          statusElement.classList.remove('text-success');
        }
      })
      .catch(error => {
        const statusElement = document.getElementById(`status-empresa-${empresaId}`);
        statusElement.innerText = "Erro ao verificar";
        statusElement.classList.add('text-danger');
      });
    });
  });
});





// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
